import axios from '../_helpers/axios';

export const vehicle_commandService = {
    get_vehicle_command,
    send_vehicle_command,
};

async function get_vehicle_command(data) {
  return new Promise((resolve, reject) => {
    axios.post('get_vehicle_command', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function send_vehicle_command(data) {
  return new Promise((resolve, reject) => {
    axios.post('send_vehicle_command', data)
    .then(resp => {
      resolve(resp.data)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
