import axios from '../_helpers/axios';

export const report_geofence_summary_kmService = {
    get_data,
    report_sync_geofence_summary_km,
};

async function get_data(data) {
  return new Promise((resolve, reject) => {
    axios.post('report_geofence_summary_km',data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function report_sync_geofence_summary_km(data) {
  return new Promise((resolve, reject) => {
    axios.post('report_sync_geofence_summary_km',data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
