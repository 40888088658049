import axios from '../_helpers/axios';

export const routeService = {
    get,
    add,
    edit,
    destroy,
    get_all,
    get_route_by_account,
    get_route_by_vehicle,
    edit_route_stops,
    edit_route_vehicles,
    edit_route_points,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('routes/' + data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('routes', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('routes/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('routes/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('routes')
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_route_by_account(data) {
  return new Promise((resolve, reject) => {
    axios.post('route_by_account', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_route_by_vehicle(data) {
  return new Promise((resolve, reject) => {
    axios.post('route_by_vehicle', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function edit_route_stops(data) {
  return new Promise((resolve, reject) => {
    axios.post('route_stops', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit_route_vehicles(data) {
  return new Promise((resolve, reject) => {
    axios.post('route_vehicles', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit_route_points(data) {
  return new Promise((resolve, reject) => {
    axios.post('route_points', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}
