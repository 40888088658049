import { driverService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, drivers: [], driver: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        driverService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        driverService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/drivers');
                    dispatch('alert/success', "Driver Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/drivers');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        driverService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/drivers');
                    dispatch('alert/success', "Driver Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/drivers');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        driverService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      driverService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/drivers');
                  dispatch('alert/success', "Driver Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/drivers');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_driver_by_account({ dispatch, commit }, data) {
        commit('request');

        driverService.get_driver_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  drivers: state => {
    return state.drivers
  },
  driver: state => {
    return state.driver
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.driver = {};
        state.drivers = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.driver = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.drivers = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const drivers = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
