import axios from '../_helpers/axios';

export const sensorService = {
  get,
  add,
  edit,
  destroy,
  get_all,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('sensors/' + data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('sensors', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('sensors/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('sensors/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('sensors')
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
