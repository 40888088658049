import { case_incidentService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, case_incidents: [], case_incident: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        case_incidentService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        case_incidentService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/case_incidents');
                    dispatch('alert/success', "Case Incident Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/case_incidents');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        case_incidentService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/case_incidents');
                    dispatch('alert/success', "Case Incident Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/case_incidents');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        case_incidentService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      case_incidentService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/case_incidents');
                  dispatch('alert/success', "Case Incident Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/case_incidents');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_case_incident_by_date({ dispatch, commit }, data) {
        commit('request');

        case_incidentService.get_case_incident_by_date(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_case_incident_by_account({ dispatch, commit }, data) {
        commit('request');

        case_incidentService.get_case_incident_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  case_incidents: state => {
    return state.case_incidents
  },
  case_incident: state => {
    return state.case_incident
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.case_incident = {};
        state.case_incidents = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.case_incident = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.case_incidents = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const case_incidents = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
