import axios from '../_helpers/axios';

export const insert_positionService = {
  upload,
};

async function upload(data) {
  return new Promise((resolve, reject) => {
    axios.post('insert_position',data)
    .then(resp => {
      if(resp){
        resolve(resp)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
