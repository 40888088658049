import { dashboardService } from '../_services';
const state = { onRequest: false, dashboard: {} };

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        dashboardService.get_data(data)
            .then(
                (resp) => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  dashboard: state => {
    return state.dashboard
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.dashboard = {};
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.dashboard = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const dashboards = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
